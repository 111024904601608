@import url('https://cdn.syncfusion.com/ej2/material.css');

.scale, .hover-box{
    transition: all .2s ease-in-out;
}
.scale:hover{
    transform: scale(1.1);
}

.hover-box:hover {
    -webkit-box-shadow: 0px 0px 11px 4px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 11px 4px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 11px 4px rgba(0,0,0,0.1);
}
text {
    font-size: 13px !important;
}